import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, RegistrationAuthority } from '@yourcause/common';
import { RegistrationAuthorityAndRegistrationIdExistForNonprofitCreate, RegistrationAuthoritiesValidForNonprofitCreate } from './registration-authorities-typings';

@Injectable({ providedIn: 'root' })
export class RegistrationAuthoritiesResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getRegistrationAuthorities () {
    const response = await this.httpRestService.get<APIResponse<RegistrationAuthority[]>>(
      'api/RegistrationAuthority/GetAll'
    );

    return response.data;
  }

  async getRegistrationAuthoritiesValidForNonprofitCreate () {
    const response = await this.httpRestService.get(
      'api/registrationAuthority/ValidForNonprofitCreate'
    );

    return response.data as RegistrationAuthoritiesValidForNonprofitCreate;
  }

  async registrationAuthorityAndRegistrationIdExist (registrationAuthorityId: number, registrationId: string) {
    const encodedRegistrationId = encodeURIComponent(registrationId);

    const response = await this.httpRestService.get(
      `api/registrationAuthority/${registrationAuthorityId}/exists?registrationId=${encodeURIComponent(encodedRegistrationId)}`
    );

    return response.data as RegistrationAuthorityAndRegistrationIdExistForNonprofitCreate;
  }
}
