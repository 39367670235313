import { RegistrationAuthority, TopLevelFilterOption } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseYcState, RegisterYCState } from '@yourcause/common/state';

@RegisterYCState()
export class RegistrationAuthoritiesState extends BaseYcState {
  readonly authorities: RegistrationAuthority[];
  readonly allRegAuthsForSelect: TopLevelFilterOption[];
  readonly regAuthsForNonprofitAdd: TypeaheadSelectOption[];
}
