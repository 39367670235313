import { PaginationOptions } from '@yourcause/common';
import { RoleInfo } from './users.model';

export interface UserRolePaginationModel {
  id: number;
  lastName: string;
  firstName: string;
  fullName: string;
  email: string;
  lastLoginDate: string;
  roles: RoleInfo[];
}

export interface YourcauseUserTableDataPayload {
  paginationOptions: PaginationOptions<UserRolePaginationModel>;
  roleIds?: number[];
}

export interface AddYourcauseUserResponseModel {
  firstName: string;
  lastName: string;
  language: string;
  acknowledgedTermsOfService: boolean;
  acknowledgedTermsOfServiceDate: string;
  isDeactivated: boolean;
  registrationNonprofitId: number;
  passwordLastChangedDate: string;
  aspNetUsersPasswordHistory?: any[];
  isInternalUser: boolean;
  email: string;
  emailConfirmed: boolean;
  passwordHash: string;
  securityStamp: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEndDateUtc: string;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  roles: RoleInfo[];
  claims?: any[];
  logins?: any[];
  id: number;
  userName: string;
}

export interface AddEditUserModalResponse {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  roles?: number[];
}

export interface EditRemoveYourcauseUserPayload {
  userId: number;
  roleIds?: number[];
}

export interface AdminFromSearch {
  firstName: string;
  lastName: string;
  email: string;
  location: string;
}

export enum YcUserRoles {
  SITE_ADMIN = 2,
  YC_ADMIN = 4,
  YC_SUPPORT = 5,
  YC_NONPROFIT_MANAGER = 6,
  COMPLIANCE_ADMIN = 7,
  IMPLEMENTATION_MANAGER = 8,
  CHECK_REISSUE_ROLE = 9,
  YC_NONPROFIT_ACH_MANAGER = 10,
  YC_REPORTING = 11,
  ENGINEERING = 12
}

export const YOURCAUSE_USER_ROLE_MAP = {
  'Platform Admin': 2,
  'YC Admin': 4,
  'YC Support': 5,
  'YC Nonprofit Manager': 6,
  'Compliance Admin': 7,
  'Implementation Manager': 8,
  'Check Reissue Role': 9,
  'YC Nonprofit ACH Manager': 10,
  'YC Reporting': 11,
  Engineering: 12
};

export const YOURCAUSE_USER_ROLE_MAP_BY_ID = {
  2: 'Platform Admin',
  4: 'YC Admin',
  5: 'YC Support',
  6: 'YC Nonprofit Manager',
  7: 'Compliance Admin',
  8: 'Implementation Manager',
  9: 'Check Reissue Role',
  10: 'YC Nonprofit ACH Manager',
  11: 'YC Reporting',
  12: 'Engineering'
};
